/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  FormControl,
  FormLabel,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  useToast,
  Alert,
  Checkbox,
} from "@chakra-ui/react";
import SimpleBooking from "components/booking/SimpleBooking";
import Loader from "components/Loader/Loader";
import { validateApiKey } from "services/assitant";
import highLevelLogo from "../../assets/img/ghl_icon.svg";
import { useTranslation } from "react-i18next";
import { getGHLcalenders } from "services/integrations";
const RealTimeBookingModal = ({
  isOpen,
  handleClose,
  setBookingData,
  bookingData,
  minDate,
  setActionData,
}: any) => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const { t } = useTranslation();
  const toast = useToast();
  const [isAutheticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allCalenderData, setAllCalenderData] = useState([]);
  const [activeTab, setActiveTab] = useState("cal.com");
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const convertTo24Hour = (time: any) => {
    const [timePart, modifier] = time.split(" ");
    let [hours, minutes] = timePart.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  };
  const validationSchema = Yup.object().shape({
    tabStatus: Yup.string(),
    api_key: Yup.string().when(["tabStatus"], ([tabStatus], schema) => {
      if (activeTab === "cal.com") {
        return Yup.string().required(t("assistantForm.APIKeyValid"));
      }
      return schema;
    }),
    eventId: Yup.string().when(["tabStatus"], ([tabStatus], schema) => {
      if (activeTab === "cal.com") {
        return Yup.string().required(t("assistantForm.calEventIdValid"));
      }
      return schema;
    }),
    calenderId: Yup.string().when(["tabStatus"], ([tabStatus], schema) => {
      if (activeTab === "gohighlevel") {
        return Yup.string().required(t("assistantForm.calIdValid"));
      }
      return schema;
    }),
    startTime: Yup.string().when(["tabStatus"], ([tabStatus], schema) => {
      if (activeTab === "openBooking") {
        return Yup.string().required(t("assistantForm.startTimeValid"));
      }
      return schema;
    }),
    endTime: Yup.string().when(["tabStatus"], ([tabStatus], schema) => {
      if (activeTab === "openBooking") {
        return Yup.string()
          .required(t("assistantForm.endTimeValid"))
          .test(
            "is-greater",
            t("assistantForm.endTimeValid1"),
            function (value) {
              const { startTime } = this.parent;
              if (!startTime || !value) {
                return true; // Skip validation if either field is empty
              }

              // Convert both times to 24-hour format
              const start = convertTo24Hour(startTime);
              const end = convertTo24Hour(value);

              // Compare the converted times
              return end > start;
            }
          );
      }
      return schema;
    }),
    bookingDays: Yup.array().when(["tabStatus"], ([tabStatus], schema) => {
      if (activeTab === "openBooking") {
        return Yup.array().min(1, t("assistantForm.selectDaysValid"));
      }
      return schema;
    }),
  });
  useEffect(() => {
    if (isOpen) {
      fetchGHLcalenders();
    }
  }, [isOpen]);

  const fetchGHLcalenders = () => {
    getGHLcalenders()
      .then((response) => {
        if (response?.data && response?.data?.calendars) {
          setAllCalenderData(response?.data?.calendars);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const formik = useFormik({
    initialValues: {
      tabStatus: bookingData?.meetingType || "cal.com",
      api_key: bookingData?.api_key || "",
      eventId: bookingData?.eventId || "",
      calenderId: bookingData?.calenderId ? bookingData?.calenderId : "",
      startTime: bookingData?.startTime ? bookingData?.startTime : "",
      endTime: bookingData?.endTime ? bookingData?.endTime : "",
      bookingDays: bookingData?.bookingDays
        ? bookingData?.bookingDays?.split(",")
        : [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values: any, { resetForm }) => {
      let apiKeyPayload = {
        api_key: values?.api_key,
        eventId: values?.eventId,
      };
      if (activeTab === "cal.com") {
        setLoading(true);
        validateApiKey(apiKeyPayload)
          .then((response) => {
            if (response?.event_type) {
              setLoading(false);
              setIsAuthenticated(true);
              setBookingData((prev: any) => ({
                ...prev,
                meetingType: "cal.com",
                api_key: values?.api_key,
                eventId: values?.eventId,
              }));
            } else {
              setLoading(false);
              toast({
                description: t("assistantForm.apiKeyNotValid"),
                status: "error",
                duration: 8000,
                isClosable: true,
                position: "top-left",
              });
            }
          })
          .catch((error: any) => {
            setLoading(false);
            console.error("Error:", error);
          });
      }
      if (activeTab === "gohighlevel") {
        setBookingData((prev: any) => ({
          ...prev,
          meetingType: "gohighlevel",
          calenderId: values?.calenderId,
        }));
        setIsAuthenticated(true);
      }
      if (activeTab === "openBooking") {
        setBookingData((prev: any) => ({
          ...prev,
          meetingType: "openBooking",
          startTime: values?.startTime,
          endTime: values?.endTime,
          bookingDays: values?.bookingDays.join(),
        }));
        setIsAuthenticated(true);
      }
    },
  });
  const resetAndCloseModal = () => {
    formik.resetForm();
    handleClose();
    setIsAuthenticated(false);
  };
  const handleTabClick = (status: any) => {
    formik.setFieldValue("tabStatus", status);
    formik.resetForm();
    setActiveTab(status);
  };

  useEffect(() => {
    if (isOpen && bookingData) {
      setActiveTab(bookingData?.meetingType || "cal.com");
      formik.setValues({
        tabStatus: bookingData?.meetingType || "cal.com",
        api_key: bookingData?.api_key || "",
        eventId: bookingData?.eventId || "",
        calenderId: bookingData?.calenderId ? bookingData?.calenderId : "",
        startTime: bookingData?.startTime ? bookingData?.startTime : "",
        endTime: bookingData?.endTime ? bookingData?.endTime : "",
        bookingDays: bookingData?.bookingDays
          ? bookingData?.bookingDays?.split(",")
          : [],
      });
    }
  }, [isOpen, bookingData]);
  return (
    <>
      <Drawer
        placement="right"
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={resetAndCloseModal}
        size={"md"}
        blockScrollOnMount={false}
      >
        <DrawerOverlay />
        <DrawerContent maxW={"650px"} className="dark:bg-navy-900">
          {loading && <Loader />}
          <DrawerHeader>
            {t("assistantForm.realTimeBooking")}{" "}
            {t("assistantForm.realTimeBooking2")}
          </DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody pb={6}>
            {!isAutheticated ? (
              <div>
                <FormLabel color={"gray"}>
                  {t("assistantForm.scheduleBookingCaption")}
                </FormLabel>

                <div>
                  <div className="mb-8 mt-8 border-b border-gray-200 dark:border-gray-700">
                    <ul
                      className="-mb-px flex flex-wrap items-center gap-5 text-sm font-medium"
                      role="tablist"
                    >
                      <li className="me-2 flex" role="presentation">
                        <div
                          className={`cursor-pointer p-1 ${
                            activeTab === "cal.com"
                              ? "border-b-2 border-blue-700"
                              : ""
                          }`}
                          onClick={() => handleTabClick("cal.com")}
                        >
                          {/* <div className="flex items-center justify-center gap-1 px-3 text-[16px] lg:px-1">
                            <img src={calLogo} alt="cal" className="w-[80px]" />
                          </div> */}
                          <div className="flex items-center justify-center gap-1 px-3 text-[18px] font-bold">
                            Cal.com
                          </div>
                        </div>
                      </li>
                      <li className="me-2 flex" role="presentation">
                        <div
                          className={`cursor-pointer p-1 ${
                            activeTab === "gohighlevel"
                              ? "border-b-2 border-blue-700"
                              : ""
                          }`}
                          onClick={() => handleTabClick("gohighlevel")}
                        >
                          <div className="flex items-center justify-center gap-1 px-3 text-[18px] font-bold">
                            <img
                              src={highLevelLogo}
                              alt="highlevel"
                              className="w-[20px]"
                            />
                            {"HighLevel"}
                          </div>
                        </div>
                      </li>
                      <li className="me-2 flex" role="presentation">
                        <div
                          className={`cursor-pointer p-1 ${
                            activeTab === "openBooking"
                              ? "border-b-2 border-blue-700"
                              : ""
                          }`}
                          onClick={() => handleTabClick("openBooking")}
                        >
                          <div className="flex items-center justify-center gap-1 px-3 text-[18px] font-bold">
                            {t("assistantForm.openBooking")}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                {activeTab === "cal.com" && (
                  <div>
                    <FormControl mt={4}>
                      <FormLabel>{t("assistantForm.APIKey")} *</FormLabel>
                      <input
                        className="h-[50px] w-full appearance-none rounded border-[1px] border-gray-500 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                        id="api_key"
                        type="text"
                        value={formik.values.api_key}
                        onChange={formik.handleChange}
                        placeholder="cal_live_xxxx"
                      />
                      {formik.touched.api_key && formik.errors.api_key ? (
                        <div className="text-red-500">
                          {formik.errors.api_key.toString()}
                        </div>
                      ) : null}
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>{t("assistantForm.calEventID")} *</FormLabel>
                      <input
                        className="h-[50px] w-full appearance-none rounded border-[1px] border-gray-500 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                        id="eventId"
                        type="number"
                        value={formik.values.eventId}
                        onChange={formik.handleChange}
                        placeholder="Ex: 641441"
                      />
                      {formik.touched.eventId && formik.errors.eventId ? (
                        <div className="text-red-500">
                          {formik.errors.eventId.toString()}
                        </div>
                      ) : null}
                    </FormControl>
                    <FormLabel color={"gray"} mt={4} fontSize={15}>
                      {t("assistantForm.pleaseCheckOur")}{" "}
                      <a
                        rel="noreferrer"
                        href="https://docs.precallai.com/general/assistants/assistantAction#real-time-booking"
                        target="_blank"
                      >
                        <label className="cursor-pointer text-blue-700 hover:underline">
                          {t("assistantForm.documentationVideo")}
                        </label>
                      </a>{" "}
                      {t("assistantForm.forCalCom")}
                    </FormLabel>
                  </div>
                )}
                {activeTab === "gohighlevel" && (
                  <div>
                    <FormControl mt={4}>
                      <FormLabel>Calendars *</FormLabel>
                      <select
                        id="calenderId"
                        name="calenderId"
                        value={formik.values.calenderId}
                        onChange={formik.handleChange}
                        className="h-[50px] w-full appearance-none rounded border-[1px] border-gray-500 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                      >
                        <option value="">Calendar select..</option>
                        {allCalenderData &&
                          allCalenderData.map((op, i) => (
                            <option key={i} value={op.id}>
                              {op.name}
                            </option>
                          ))}
                      </select>
                      {formik.touched.calenderId && formik.errors.calenderId ? (
                        <div className="text-red-500">
                          {formik.errors.calenderId.toString()}
                        </div>
                      ) : null}
                    </FormControl>
                    <FormLabel color={"gray"} mt={4} fontSize={15}>
                      {t("assistantForm.pleaseCheckOur")}{" "}
                      <a
                        rel="noreferrer"
                        href="https://docs.precallai.com/general/integrations/gohighlevel"
                        target="_blank"
                      >
                        <label className="cursor-pointer text-blue-700 hover:underline">
                          {t("assistantForm.documentationVideo")}
                        </label>
                      </a>{" "}
                      {t("assistantForm.forGHLCom")}
                    </FormLabel>
                  </div>
                )}
                {activeTab === "openBooking" && (
                  <div>
                    <Alert status="info">
                      {t("assistantForm.openBookingText")}
                    </Alert>
                    <div className="mt-3 flex flex-col gap-6 md:flex-row">
                      <div className="mt-4 flex w-full flex-col gap-1 md:w-[50%]">
                        <div className="mb-4">
                          <FormLabel>
                            {t("assistantForm.startTime")} *
                          </FormLabel>
                          {/* <input
                            type="time"
                            name="startTime"
                            id="startTime"
                            value={formik.values.startTime}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          /> */}
                          <select
                            name="startTime"
                            id="startTime"
                            value={formik.values.startTime}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                          >
                            <option value="">
                              {t("assistantForm.selectStartTime")}
                            </option>
                            {Array(24)
                              .fill(0)
                              .map((_, index) => (
                                <React.Fragment key={index}>
                                  <option
                                    value={`${(index % 12 || 12)
                                      .toString()
                                      .padStart(2, "0")}:00 ${
                                      index < 12 ? "AM" : "PM"
                                    }`}
                                  >
                                    {(index % 12 || 12)
                                      .toString()
                                      .padStart(2, "0")}
                                    :00 {index < 12 ? "AM" : "PM"}
                                  </option>
                                  <option
                                    value={`${(index % 12 || 12)
                                      .toString()
                                      .padStart(2, "0")}:15 ${
                                      index < 12 ? "AM" : "PM"
                                    }`}
                                  >
                                    {(index % 12 || 12)
                                      .toString()
                                      .padStart(2, "0")}
                                    :15 {index < 12 ? "AM" : "PM"}
                                  </option>
                                  <option
                                    value={`${(index % 12 || 12)
                                      .toString()
                                      .padStart(2, "0")}:30 ${
                                      index < 12 ? "AM" : "PM"
                                    }`}
                                  >
                                    {(index % 12 || 12)
                                      .toString()
                                      .padStart(2, "0")}
                                    :30 {index < 12 ? "AM" : "PM"}
                                  </option>
                                  <option
                                    value={`${(index % 12 || 12)
                                      .toString()
                                      .padStart(2, "0")}:45 ${
                                      index < 12 ? "AM" : "PM"
                                    }`}
                                  >
                                    {(index % 12 || 12)
                                      .toString()
                                      .padStart(2, "0")}
                                    :45 {index < 12 ? "AM" : "PM"}
                                  </option>
                                </React.Fragment>
                              ))}
                          </select>
                          {formik.touched.startTime &&
                          formik.errors.startTime ? (
                            <div className="text-red-500">
                              {formik.errors.startTime.toString()}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="mt-4 flex w-full flex-col gap-1 md:w-[50%]">
                        <div className="mb-4">
                          <FormLabel>{t("assistantForm.endTime")} *</FormLabel>
                          {/* <input
                            type="time"
                            name="endTime"
                            id="endTime"
                            value={formik.values.endTime}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          /> */}
                          <select
                            name="endTime"
                            id="endTime"
                            value={formik.values.endTime}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                          >
                            <option value="">
                              {t("assistantForm.selectEndTime")}
                            </option>
                            {Array(24)
                              .fill(0)
                              .map((_, index) => (
                                <React.Fragment key={index}>
                                  <option
                                    value={`${(index % 12 || 12)
                                      .toString()
                                      .padStart(2, "0")}:00 ${
                                      index < 12 ? "AM" : "PM"
                                    }`}
                                  >
                                    {(index % 12 || 12)
                                      .toString()
                                      .padStart(2, "0")}
                                    :00 {index < 12 ? "AM" : "PM"}
                                  </option>
                                  <option
                                    value={`${(index % 12 || 12)
                                      .toString()
                                      .padStart(2, "0")}:15 ${
                                      index < 12 ? "AM" : "PM"
                                    }`}
                                  >
                                    {(index % 12 || 12)
                                      .toString()
                                      .padStart(2, "0")}
                                    :15 {index < 12 ? "AM" : "PM"}
                                  </option>
                                  <option
                                    value={`${(index % 12 || 12)
                                      .toString()
                                      .padStart(2, "0")}:30 ${
                                      index < 12 ? "AM" : "PM"
                                    }`}
                                  >
                                    {(index % 12 || 12)
                                      .toString()
                                      .padStart(2, "0")}
                                    :30 {index < 12 ? "AM" : "PM"}
                                  </option>
                                  <option
                                    value={`${(index % 12 || 12)
                                      .toString()
                                      .padStart(2, "0")}:45 ${
                                      index < 12 ? "AM" : "PM"
                                    }`}
                                  >
                                    {(index % 12 || 12)
                                      .toString()
                                      .padStart(2, "0")}
                                    :45 {index < 12 ? "AM" : "PM"}
                                  </option>
                                </React.Fragment>
                              ))}
                          </select>
                          {formik.touched.endTime && formik.errors.endTime ? (
                            <div className="text-red-500">
                              {formik.errors.endTime.toString()}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <FormLabel>{t("assistantForm.selectDays")} *</FormLabel>
                      <div className="mt-4">
                        {daysOfWeek.map((day, index) => (
                          <div key={index} className="mb-3 flex items-center">
                            <Checkbox
                              id="bookingDays"
                              colorScheme="cyan"
                              value={day}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              isChecked={formik.values.bookingDays.includes(
                                day
                              )}
                            >
                              {day}
                            </Checkbox>
                          </div>
                        ))}
                      </div>
                      {formik.touched.bookingDays &&
                      formik.errors.bookingDays ? (
                        <div className="text-red-500">
                          {formik.errors.bookingDays.toString()}
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <div className="mb-4">
                  <SimpleBooking
                    setBookingData={setBookingData}
                    bookingData={bookingData}
                    setActionData={setActionData}
                    resetAndCloseModal={resetAndCloseModal}
                    setIsAuthenticated={setIsAuthenticated}
                  />
                </div>
              </div>
            )}
          </DrawerBody>

          {!isAutheticated && (
            <DrawerFooter display={"flex"} justifyContent={"start"}>
              <div>
                <Button
                  colorScheme="blue"
                  mr={3}
                  onClick={() => formik.handleSubmit()}
                  className="capitalize dark:bg-blue-700 dark:text-white"
                >
                  {t("next")}
                </Button>
                <Button onClick={resetAndCloseModal}>{t("cancel")}</Button>
              </div>
            </DrawerFooter>
          )}
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default RealTimeBookingModal;
